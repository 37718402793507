// component imports 
import Topbar from "./components/Topbar";
import AnimatedRoutes from './components/AnimatedRoutes'

// css
import './style/App.css'

// router dom
import { BrowserRouter as Router } from 'react-router-dom'

function App() {
  return (
    <div className="overflow-y-hidden">
      <Router>
        <Topbar />
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;
