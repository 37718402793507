import React from 'react'

export default function Topbar() {
   return (
      <nav className='nav'>
         <ul style={{ gap: ".1rem" }}>
            <li className='ml-[-.17in]'>
               <a href='/' className='site-title'>
                  Noetiverse
                  <span className="text-xs align-top mb-[.1in] ml-[-.03in]">®</span>
                  <span style={{ marginLeft: "-.02in", fontWeight: "100" }}>&#8212;</span>
               </a>
            </li>
            <li style={{ backgroundColor: "slategrey" }}>
               <a href='/' className="text-[1.2rem] mt-[.13rem] ml-[-.07in]">Publisher of cosmological speculative fiction and epic fantasies</a>
            </li>
         </ul>
         <ul>
            <li>
               <a href='/about'>About The Author</a>
            </li>
            <li>
               <a href='/'>Home</a>
            </li>
         </ul>
      </nav>
   )
}
