import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

// custom pages
import Home from '../pages/Home.tsx';
import About from '../pages/About.tsx';

// animation
import { AnimatePresence } from 'framer-motion';


function AnimatedRoutes() {
   const location = useLocation();

   return (
      <AnimatePresence>
         <Routes location={location} key={location.pathname}>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
         </Routes>
      </AnimatePresence>
   )
}

export default AnimatedRoutes