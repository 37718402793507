import React, { useState, CSSProperties, useEffect } from 'react'
import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web'
import '../style/Carousel.css';

// @ts-ignore
import styles from './styles.module.css';

// // custom
import 'bootstrap/dist/css/bootstrap.css';


const pages: ((props: AnimatedProps<{ style: CSSProperties }>) => React.ReactElement)[] = [
  ({ style }) => <animated.div style={{ ...style, background: 'transparent' }}>
    <div className="flex flex-row w-full h-screen">

      {/* introduction */}
      <div className="flex flex-col w-1/2 h-auto mt-[2.70in] mr-[.25in] items-center">
        <h3>Welcome to the Noeticus Legend</h3>
        <h4>A series of cosmogonical fiction and epic high-fantasy books</h4>
      </div>

      {/* book titles */}
      <div className="flex flex-col w-1/2 gap-10 mt-2 items-center justify-center">
        <h4 className="mr-[2.3in] mb-10">NOETICUS: A Story of Cosmic Love</h4>
        <h4 className="mb-40">NOETICUS: The Universe Lives for You</h4>
        <h4 className="h-1/3 ml-[1.6in] mb-[.7in]">NOETICUS: A Golden Age Emerges</h4>
      </div>
    </div>
  </animated.div>,
  ({ style }) => <animated.div style={{ ...style, background: 'transparent' }}>
    <div className="container" style={{ marginLeft: "2in" }}>
      <div className="row align-items-center">
        <div className="col-sm text-light home-description justify-content-center my-auto align-self-center">
          <h1 className="text-3xl">
            NOETICUS<span className="text-xs align-[13px]">®</span>
          </h1>
          <p>by <text style={{ marginLeft: ".08in" }}>Frederick Vaughn Funk</text></p>
        </div>
        <div className="col-sm">
        </div>
        <div className="col-sm text-center align-self-center">
          <div className='flex flex-col ml-[1in] mt-[2in] justify-center items-center'>
            <img className='checkout-image' src={"/bookCover.png"} alt='book cover' />
            <div style={{ padding: '10px' }}>
              <a
                className='flex items-center justify-center text-lg h-12 w-52 rounded-sm bg-[#00028A] text-white'
                target="_blank"
                rel="noreferrer"
                href="https://square.link/u/MC7SlNdd?src=embed">
                Buy now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </animated.div>,
  ({ style }) => <animated.div style={{ ...style, background: 'transparent' }}>
    <div className="container" style={{ marginLeft: "2in" }}>
      <div className="row align-items-center">
        <div className="col-sm text-light home-description justify-content-center my-auto align-self-center">
          <h1 className="text-3xl">
            NOETICUS<span className="text-xs align-[13px]">®</span>
          </h1>
          <p>by <text style={{ marginLeft: ".08in" }}>Frederick Vaughn Funk</text></p>
        </div>
        <div className="col-sm">
        </div>
        <div className="col-sm text-center align-self-center">
          <div className='flex flex-col ml-[1in] mt-[2in] justify-center items-center'>
            <img className='checkout-image' src={"/bookCover3.png"} alt='book cover' />
            <div style={{ padding: '10px' }}>
              <div className='flex items-center justify-center text-lg h-12 w-52 rounded-sm bg-[#00028A]'>
                Coming Soon
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </animated.div>,
  ({ style }) => <animated.div style={{ ...style, background: 'transparent' }}>
    <div className="container" style={{ marginLeft: "2in" }}>
      <div className="row align-items-center">
        <div className="col-sm text-light home-description justify-content-center my-auto align-self-center">
          <h1 className="text-3xl">
            NOETICUS<span className="text-xs align-[13px]">®</span>
          </h1>
          <p>by <text style={{ marginLeft: ".08in" }}>Frederick Vaughn Funk</text></p>
        </div>
        <div className="col-sm">
        </div>
        <div className="col-sm text-center align-self-center">
          <div className='flex flex-col ml-[1in] mt-[2in] justify-center items-center'>
            <img className='checkout-image' src={"/bookCover2.png"} alt='book cover' />
            <div style={{ padding: '10px' }}>
              <a
                className='flex items-center justify-center text-lg h-12 w-52 rounded-sm bg-[#00028A] text-white'
                target="_blank"
                rel="noreferrer"
                href="https://square.link/u/HBMR9JDF?src=embed">
                Buy now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </animated.div>
]

export default function Home() {

  const [index, set] = useState(0)
  const [from, setFrom] = useState(100)
  const [leave, setLeave] = useState(-50)
  const transRef = useSpringRef()
  const transitions = useTransition(index, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, transform: `translate3d(${from}%,0,0)` },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: `translate3d(${leave}%,0,0)` },
  })

  useEffect(() => {
    transRef.start()
  }, [index, transRef])

  const handlePrevClick = () => {
    set(state => (state + 3) % 4)
    setFrom(-100)
    setLeave(50)
  };

  const handleNextClick = () => {
    set(state => (state + 1) % 4)
    setFrom(100)
    setLeave(-50)
  };

  return (
    <div className="carousel-container">
      <div className="carousel-video">
        <video autoPlay muted loop>
          <source src={"/planetLoop.mp4"} type="video/mp4" />
        </video>
      </div>
      <div className={`flex fill ${styles.container}`}>
        {transitions((style, i) => {
          const Page = pages[i]
          return <Page style={style} />
        })}
      </div>
      <button className="carousel-prev" onClick={handlePrevClick}>
        &lt;
      </button>
      <button className="carousel-next" onClick={handleNextClick}>
        &gt;
      </button>
    </div>
  );
}


